import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tracking-api-reference"
    }}>{`Tracking API Reference`}</h1>
    <p>{`Welcome to the Tracking API. `}</p>
    <p>{`This API should be used when there is no module available for your language or they doesn't satisfy your needs.`}</p>
    <p>{`We strive to keep it as simple as possible.`}</p>
    <p>{`Is something missing from the API?
Let us know `}<a parentName="p" {...{
        "href": "mailto:hello@nucleus.sh"
      }}>{`hello@nucleus.sh`}</a></p>
    <h3 {...{
      "id": "events-types"
    }}>{`Events Types`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Requires extra data`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`init`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First event to send when starting the app`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`yes`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default type, for reporting actions or anything else`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submit an error, `}<inlineCode parentName="td">{`name`}</inlineCode>{` should contain the error type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`yes`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`userid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set a new user ID for this user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`props`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set propserties for this user ID based on the `}<inlineCode parentName="td">{`data`}</inlineCode>{` field.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`heartbeat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Websockets only`}</em>{`: send every minute to keep the connection on. This only require to pass the `}<strong parentName="td">{`machineId`}</strong>{` field alongside`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The first thing you need to send when the user opens the app is an `}<strong parentName="p">{`init`}</strong>{` event upon which most of the analytics relies on. `}</p>
    <p><strong parentName="p">{`If you don't send it first, no data will appear in your dashboard.`}</strong></p>
    <h3 {...{
      "id": "events-data"
    }}>{`Events Data`}</h3>
    <p>{`Nucleus expect to receive the analytics data as a JSON object, containing a `}<inlineCode parentName="p">{`data`}</inlineCode>{` array property.`}</p>
    <p>{`This array should contain one or multiple events you want to report.`}</p>
    <p><em parentName="p">{`Basic events data`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type, see below for all the possible values (default: "event")`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`required`}</strong>{` if `}<inlineCode parentName="td">{`type`}</inlineCode>{` is `}<strong parentName="td">{`event`}</strong>{` or `}<strong parentName="td">{`error`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sessionId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`required`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4-digits number that identifies the current session`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the event (ISO 8601 or milleseconds since 1970). If not provided we'll use the time the server receives the event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Short ID that will be returned in confirmation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`userId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identify the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machineId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`required`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hashed identifier of the machine (ie mac adress)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`payload`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aditionnal data attached to the event`}</td>
        </tr>
      </tbody>
    </table>
    <p><em parentName="p">{`Extra events data`}</em></p>
    <p>{`If you are reporting either an `}<strong parentName="p">{`error`}</strong>{` or the first `}<strong parentName="p">{`init`}</strong>{` event, you need to attach those extra data:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`platform`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`darwin`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually 'win32', 'windows', 'mac', 'darwin' or 'linux'`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`osVersion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`18.2.0`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Current installed version of the OS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`totalRam`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total RAM available on the user device`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`version`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`0.1.0`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Version of the app installed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`en-US`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Locale of the user`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`They are not required with regular events to save bandwidth.`}</p>
    <p>{`Example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "data": [{
        "event": String, // Name of the event
        "id": String, // OPTIONAL A random id for the event that will be returned when the query succeeds, can be used to make sure no events are reported two times.
        "userId": String, // A string to identify the user
        "machineId": String, // A hashed identifier of the machine
        "sessionId": Int, // A random 4-digits number that identifies the current session
        "platform": String, // 
        "osVersion": String, // The version of the OS
        "totalRam": Int, // The total number of RAM available, in GB
        "version": String, // Installed version of the app
        "language": String, // Locale of the user (i.e. 'en-US')
        "payload": {}, // Any additionnal data that you want to report along the event
    }]
}
`}</code></pre>
    <h2 {...{
      "id": "track-via-websockets"
    }}>{`Track via Websockets`}</h2>
    <p>{`This is the recommended protocol to submit data. `}</p>
    <p>{`It is the most efficient in terms of bandwidth and battery. This is what the modules use behind the scenes.`}</p>
    <p>{`Latency will be vastly better compared to normal HTTP requests.`}</p>
    <p><strong parentName="p">{`Endpoint:`}</strong>{`
`}<inlineCode parentName="p">{`wss://app.nucleus.sh/:appId/`}</inlineCode></p>
    <p>{`Send your data as a JSON serialized string message.`}</p>
    <p>{`To prevent data lost due to network errors, when Nucleus receives an event it will send your client a message containing an array `}<inlineCode parentName="p">{`reportedIds`}</inlineCode>{` of the previously reported events so you can safely assume they were handled by the server.`}</p>
    <h2 {...{
      "id": "track-via-http"
    }}>{`Track via HTTP`}</h2>
    <p>{`Use this if you'd like to report data where Websockets aren't available.`}</p>
    <p>{`Keep in mind that with the HTTP method the "Live view" in the dashboard won't work.`}</p>
    <p><inlineCode parentName="p">{`POST https://app.nucleus.sh/app/:appId/track`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<inlineCode parentName="td">{`events`}</inlineCode>{` objects`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`This endpoint doesn't require authentication but is `}<strong parentName="p">{`subject to IP rate limiting`}</strong>{`. `}</p>
    <p>{`If you expect lots of events to be reported within a short time interval, you should condense them under one request. For example, save the events in memory (with their correct date), and every 30 seconds report them to the server.`}</p>
    <p>{`Nucleus will respond with an array `}<inlineCode parentName="p">{`reportedIds`}</inlineCode>{` containing the IDs of the events just reported.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      